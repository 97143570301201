import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiSelect: {
      icon: {
        color: "#fff",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "0px",
        "@media (min-width: 600px)": {
          minHeight: "0px !important",
        },
      },
    },
    MuiIconButton: {
      root: {
        flex: "0 0 auto",
        color: "#fff",
        width: "40px",
        height: "40px",
        overflow: "visible",
        borderRadius: "50%",

        "@media (max-width: 767px)": {
          width: "30px",
          height: "30px",
        },
      },
    },
    MuiDialogActions: {
      root: {
        marginRight: "14px",
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: "none",
      },
    },
    MuiMenu: {
      list: {
        padding: "10px ",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "12px !important",
        marginBottom: "0px !important",
        /* height: 33px; */
      },
      root: {
        border: "0",
        width: "100%",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
        marginTop: "12px",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiList: {
      padding: {
        paddingTop: "0px",
        paddingBottom: "8px",
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: "0px",
        paddingTop: "0x",
      },
      gutters: {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiTableCell: {
      alignCenter: {
        textAlign: "left",
      },
      alignRight: {
        textAlign: "left",
      },
      root: {
        borderBottom: "none",
        padding: "0px 16px 16px 16px",
        textAlign: "left",
      },
      body: {
        color: "#fff",
      },
      head: {
        color: "#fff",
        backgroundColor: "#000",
        fontSize: "16px",
        paddingTop: "20px",
        textAlign: "start",
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: "#000",
      },
      caption: {
        color: "#A7A1A1",
        fontSize: "12px",
      },
      subtitle2: {
        color: "#fff",
        lineHeight: "20px !important",
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: "#fff",
        "&.Mui-selected": {
          color: "#fff",
        },
      },
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #fff",
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        fontSize: "14px",
        lineHeight: "1.67",
        letterSpacing: "1px",
        textTransform: "uppercase",
        WebkitTextDecoration: "none",
        textDecoration: "none",
        padding: "14px 18px",
        WebkitTransition: "350ms",
        transition: "350ms",
        cursor: "pointer",
        color: "#fff",

        backgroundColor: "transparent",
        border: "1px solid #ff1919",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "#ff1919",
        },
      },

      containedSecondary: {
        fontSize: "14px",
        lineHeight: "1.67",
        letterSpacing: "1px",
        textTransform: "uppercase",
        WebkitTextDecoration: "none",
        textDecoration: "none",
        padding: "14px 18px",
        WebkitTransition: "350ms",
        transition: "350ms",
        cursor: "pointer",
        color: "#fff",
        backgroundColor: "#ff1919",
        border: "1px solid #ff1919",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      root: {
        color: "#fff",
      },
      contained: {
        color: "#000",
        backgroundColor: "#fff",
      },

      outlined: {
        border: "1px solid #797979",
      },
      outlinedSizeSmall: {
        width: "185px",
        height: "42px",
        borderRadius: "9px",
        fontSize: "12px",
        "@media (max-width: 767px)": {
          fontSize: "10px",
        },
      },
      outlinedSecondary: {
        color: "#FDB95A",
        border: "1px solid #FDB95A",
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: " #2A292E",
      },
    },
    MuiPaper: {
      rounded: {
        border: "20px",
      },
      root: {
        backgroundColor: "#302F35",
        color: "#fff",
      },
      outlined: {
        backgroundColor: "#2A292E",
        border: "1px solid #797979",
      },
    },
    PrivateTabIndicator: {
      colorPrimary: {
        backgroundColor: "#FF2626",
      },
    },
    MuiOutlinedInput: {
      root: {
        // "&:hover": {
        //   border: ".1px solid #797979",
        // },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ffffff",
          },
        },
      },

      input: {
        padding: "11.5px 14px !important",
      },

      notchedOutline: {
        border: "1px solid #797979",
        borderColor: "none",
      },
    },

    MuiFormLabel: {
      root: {
        color: "#fff",
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
        marginRight: "0px",
        fontSize: "12px !important",
      },
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        color: "#fff",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#fff",
      },
    },
    MuiSelect: {
      iconOutlined: {
        color: "#fff",
        right: "10px",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "#fff",
        width: "15px !important",
        height: "15px !important",
        fontSize: "12px !important",
      },
    },
    MuiAlert: {
      standardError: {
        color: "none",
        backgroundColor: "none",
      },
    },

    MuiPagination: {
      ul: {
        background: "none !important",
        height: "41px !important",
        width: "auto !important",
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: "4px",
      },
    },
    MuiPaginationItem: {
      root: {
        color: "#fff !important",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#302f35",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {
      MuiInputBase: {
        input: {
          font: "inherit",
          color: "#fff",
          width: "100%",
          border: "0",
          height: "1.1876em",
          margin: "0",
          display: "block",
          // padding: "16px 0 8px",
          minWidth: "0",
          background: "none",
          boxSizing: "content-box",
          animationName: "mui-auto-fill-cancel",
          letterSpacing: "inherit",
          animationDuration: "10ms",
          WebkitTapHighlightColor: "transparent",
          "&::placeholder": {
            opacity: 1,
            color: "#fff",
          },
        },
        root: {
          fontSize: "12px !important",
        },
        inputMultiline: {
          height: "40px",
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: "#fff",
      },
      secondary: {
        main: "#FF9933",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#282C34",
        dark: "#1c2025",
        paper: "#282C34",
      },
      primary: {
        main: "#1f73b7",
      },
      secondary: {
        main: "#1f73b7",
      },
      text: {
        primary: "#e6e5e8",
        secondary: "#adb0bb",
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
