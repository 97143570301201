export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
  },
  h2: {
    fontSize: "40px",
    fontWeight: "500",
    color: "#fff",
    lineHeight: "1.2",
    letterSpacing: "-0.00833em",

    "@media (max-width: 767px)": {
      fontSize: "30px",
    },
  },
  h3: {
    fontSize: "25px",
    fontWeight: "500",
    color: "#ff1919",
    lineHeight: "45px",
    letterSpacing: "-0.00833em",
  },
  h4: {
    fontSize: "25px",
    fontWeight: "600",
    color: "#fff",
    lineHeight: "1.2",
    letterSpacing: "-0.00833em",
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: "'Montserrat', sans-serif",
  },
  h6: {
    fontWeight: 500,
    fontSize: 16,
    fontFamily: "'Montserrat', sans-serif",
    color: "#fff",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    fontFamily: "'Montserrat', sans-serif",
  },
  body1: {
    fontSize: "12px",
    fontWeight: "500",
  },
  body2: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "25px",
  },

  subtitle2: {
    fontWeight: 400,
    fontSize: 12,
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: 11,
  },
  body1: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: "'Montserrat', sans-serif",
    color: "#fff",
    lineHeight: "25px",
  },
};
