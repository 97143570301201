import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfig from "src/config/APICongig";
import { toast } from "react-toastify";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Creattur ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  //   const [userData, setUserBalanceData] = useState({});
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);
  const [kycData, setKycData] = useState([]);
  console.log("asdfsdfasdfasd------", userData);

  let data = {
    userLoggedIn: isLogin,
    userData,
    kycData,
    getProfileHandler: () => getProfileHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };
  const getProfileHandler = async () => {
    try {
      const res = await axios.get(apiConfig.myAccount, {
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        setKycData(res?.data?.data?.kyc?.document?.reverse()[0]);
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getProfileHandler();
  }, []);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
