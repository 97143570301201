import React, { useState, useEffect, useContext } from "react";
import NotificationsList from "src/component/notifications";
import {
  Grid,
  Box,
  Button,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Page from "src/component/Page";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import NoDataFound from "src/DataNotFound";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { useLocation } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { useHistory, Link as RouterLink } from "react-router-dom";
export default function Notifications({ popUp }) {
  const [notificationList, setNotificationList] = useState([]);
  const auth = useContext(AuthContext);
  const userdata = auth.userData ? auth.userData : "";
  const [open, setopen] = useState(false);
  const [count, setCount] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (!window.sessionStorage.getItem("token")) {
      history.push("/login");
    }
  }, [window.sessionStorage.getItem("token")]);

  const [isLoading, setIsLoading] = useState(false);
  const getNotifictionList = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(ApiConfig.getNotification, {
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem("token")}`,
          role: "USER",
        },
        params: {
          fkUserId: userdata?.userId,
        },
      });
      console.log("res", res);
      if (res.data.status === 1618) {
        setCount(res.data.data.filter((data) => !data.isSeen));
        setNotificationList(res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.response.data.responseMessage);
      console.error(err.response);
      setIsLoading(false);
    }
  };
  const clearNotifictionList = async (id) => {
    try {
      setIsLoading(true);
      const res = await Axios({
        method: "POST",
        url: ApiConfig.clearNotification,
        headers: {
          authorization: `Bearer ${window.sessionStorage.getItem(
            "loginToken"
          )}`,
          userId: id,
        },
      });
      if (res.data.status === 200) {
        toast.success(res.data.message);
        getNotifictionList();
        setNotificationList([]);
        // setNotificationList("null");
      } else {
        toast.warn(res.data.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("ERROR", error);
      toast.error("something went wrong");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userdata?.userId) {
      getNotifictionList();
    }
  }, [userdata?.userId]);
  useEffect(() => {
    setNotificationList([]);
  }, []);

  return (
    <Page title="Notifications">
      <Box className="classes.notificationHeading">
        {popUp ? (
          ""
        ) : (
          <>
            <Box display="flex" className="notificationbox">
              <Typography
                variant="h5"
                pt={2}
                style={{ marginTop: "83px", fontSize: "30px" }}
              >
                Notifications
              </Typography>
            </Box>
          </>
        )}
        <Box mb={3}>
          {popUp ? (
            <Box display="flex" className="notificationbox">
              <Typography variant="h5" pt={2}>
                Notifications
              </Typography>
            </Box>
          ) : (
            ""
          )}

          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "-38px",
            }}
          >
            {popUp && notificationList && notificationList.length !== 0 && (
              <Box
                pt={1}
                style={{ display: "flex", justifyContent: "end" }}
                textAlign="right"
              >
                <Button onClick={() => setopen(true)}>clear all</Button>
                &nbsp;
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "/notifications",
                    });
                  }}
                >
                  View all
                </Button>
              </Box>
            )}
            {popUp
              ? ""
              : notificationList &&
                notificationList.length !== 0 && (
                  <>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button onClick={() => setopen(true)}>clear all</Button>
                    </Box>
                  </>
                )}
          </Box>
        </Box>
        {isLoading ? (
          <Box style={{ marginTop: "43px" }}>
            <ButtonCircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4} sm={12} style={{ margin: "0" }}>
            {popUp
              ? notificationList.slice(0, 4).map((data, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={i}
                      style={{ padding: "15px 0" }}
                    >
                      <NotificationsList popUp={popUp} data={data} index={i} />
                    </Grid>
                  );
                })
              : notificationList &&
                notificationList.map((data, i) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} key={i}>
                      <NotificationsList popUp={popUp} data={data} index={i} />
                    </Grid>
                  );
                })}
          </Grid>
        )}
        {popUp ? (
          <>
            {" "}
            {!isLoading && notificationList && notificationList.length === 0 && (
              <Box pt={3} style={{}}>
                <NoDataFound style={{ fontSize: "16px" }} />
              </Box>
            )}
          </>
        ) : (
          ""
        )}
      </Box>
      <div>
        <Dialog
          open={open}
          onClick={() => setopen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            Clear All Notification
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to clear all the notifications?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => setopen(false)}
              autoFocus
              style={{ padding: "6px 20px" }}
            >
              No
            </Button>
            <Button
              variant="containedPrimary"
              style={{ padding: "5px 20px", textTransform: "capitalize" }}
              onClick={() => clearNotifictionList(userdata?.userId)}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {popUp ? (
        ""
      ) : (
        <>
          {!isLoading && notificationList && notificationList.length === 0 && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "center",
                marginTop: "20px",
              }}
            >
              <NoDataFound style={{ fontSize: "16px" }} />
            </Box>
          )}
        </>
      )}
    </Page>
  );
}
